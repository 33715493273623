import React from "react";
import styled from "styled-components";

import { Container, PageWidth, Section as StandardSection } from "@util/standard";
import { Maybe, SanityBlockContent } from "@graphql-types";
import { BlocksContent } from "@global";
import { mediaQuery } from "@util/constants";

interface Props {
  title: Maybe<string> | undefined;
  data: Maybe<SanityBlockContent> | undefined;
  color: Maybe<string> | undefined;
}

export default function DevelopmentProgress({ data, color, title }: Props) {
  if (data == null) return null;

  return (
    <Section noMargins color={color!}>
      <PageWidth>
        <Container margin="60px auto" width="100%" alignItems="center" isMobileColumn>
          <p className="h2">{title ?? "Development Progress"}</p>
          <BlocksContent data={data} />
        </Container>
      </PageWidth>
    </Section>
  );
}

const Section = styled(StandardSection)<{ color: string }>`
  background-color: ${props => props.color};

  div {
    .h2 {
      margin-right: 120px;
      ${mediaQuery.mobileDown} {
        margin-right: auto;
        margin-left: 0px;
        margin-bottom: 20px;
      }
    }
    p {
      margin: 0;
    }
  }
`;
