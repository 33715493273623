import React, { ReactNode } from "react";
import styled from "styled-components";

import { Maybe } from "@graphql-types";
import { Container, PageWidth as StandardPageWidth, Section } from "@util/standard";
import { mediaQuery } from "@util/constants";
import { useStore } from "@state/store";

interface Props {
  heading: Maybe<string> | undefined;
  children?: ReactNode;
}

export default function VillageLandingHero({ heading, children }: Props) {
  const { pageColor } = useStore();

  return (
    <Section noMargins backgroundColor="navy">
      <PageWidth hasChildren={false} noMax>
        <HeroContentContainer pageColor={pageColor}>
          <TextWrapper>
            <h1>{heading}</h1>
          </TextWrapper>
          {children}
        </HeroContentContainer>
      </PageWidth>
    </Section>
  );
}

const PageWidth = styled(StandardPageWidth)<{ hasChildren: boolean }>`
  padding-top: 165px;
  padding-bottom: 45px;

  ${mediaQuery.smallLaptopDown} {
    padding-top: 150px;
  }

  ${mediaQuery.ipadProDown} {
    padding-top: 222px;
    padding-bottom: 80px;

    .button-white {
      margin: 20px 10px 0 0;
    }
  }
`;

const HeroContentContainer = styled(Container)<{ pageColor: string }>`
  position: relative;
  justify-content: space-between;

  h1 {
    display: flex;
    flex-direction: column;
    color: ${props => props.pageColor};
  }

  ${mediaQuery.smallLaptopDown} {
    max-width: unset;
  }

  ${mediaQuery.tabletDown} {
    flex-direction: column;
    h1 {
      margin-bottom: 20px;
    }
  }
`;

const TextWrapper = styled(Container)`
  justify-content: space-between;
  width: 30%;
  ${mediaQuery.tabletDown} {
    width: 100%;
    flex-direction: column;
  }
`;
