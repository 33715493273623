import { SanityVillage } from "@graphql-types";
import { IconMarkerPlain, IconPhone } from "@util/assets";
import { colors, fontFamilies, mediaQuery, villageSubPagePaths } from "@util/constants";
import { BlocksContent, Link } from "@global";
import React from "react";
import styled from "styled-components";
import { defaultButtonStyle } from "@util/standard";
import ContactPersonDetail from "./contactPersonDetail";

interface Props {
  data: SanityVillage;
}

export default function VillageExtraHeroContent({ data }: Props) {
  const {
    city,
    street,
    careHomeContacts,
    suburb,
    receptionContact,
    salesEnquiryContacts,
    postCode,
    region,
    slug,
    googleMyBusinessUrl,
    contactDetailsRichText,
  } = data;
  const villageSlug = `${region?.slug?.current}/${slug?.current}`;
  return (
    <Wrapper>
      <p className="contact-heading">Village contact details</p>
      {contactDetailsRichText ? (
        <StyledBlocksContent data={contactDetailsRichText} />
      ) : (
        <>
          <div className="details-wrapper">
            <div className="col-1">
              <div className="detail detailAddress smScreenUp">
                <IconMarkerPlain />
                <p className="heading">Address:</p>
                <div className="address-wrapper">
                  {street && <p>{street}</p>}
                  <p>
                    {suburb && suburb}, {city && city}, {postCode && postCode}
                  </p>
                  {googleMyBusinessUrl && (
                    <a href={googleMyBusinessUrl} target="_blank">
                      Get directions on Google
                    </a>
                  )}
                </div>
              </div>

              {receptionContact && receptionContact.phone && (
                <>
                  <div className="detail smScreenUp">
                    <IconPhone />
                    <p className="heading">Reception:</p>
                    <div className="detail-content">
                      <p>Ph </p>
                      <a href={`tel:${receptionContact.phone}`}> {receptionContact.phone}</a>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="col-2">
              {careHomeContacts && careHomeContacts.length >= 1 && (
                <div className="detail contact-person-details">
                  <IconPhone />
                  <p className="heading">Care home: </p>
                  <div className="detail-content">
                    <ContactPersonDetail
                      hideContactName
                      contactPerson={careHomeContacts[0]}
                      phoneUnit="Ph "
                      hideEmail
                      hideOpening
                    />
                  </div>
                </div>
              )}

              {salesEnquiryContacts?.[0]?.phone && (
                <div className="detail contact-person-details">
                  <IconPhone />
                  <p className="heading">Independent living sales: </p>
                  <div className="detail-content">
                    <ContactPersonDetail
                      hideContactName
                      contactPerson={salesEnquiryContacts[0]}
                      hideEmail
                      hideOpening
                      phoneUnit="Ph "
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      <ul className="buttons">
        <li>
          <Link linkText="Enquiry form" internalLink={villageSubPagePaths(villageSlug).contact} />
        </li>
        <li>
          <Link linkText="Opening hours" internalLink={villageSubPagePaths(villageSlug).contact} />
        </li>
      </ul>
    </Wrapper>
  );
}

const StyledBlocksContent = styled(BlocksContent)`
  p {
    margin: 0;
  }
`;

const Wrapper = styled.div`
  display: block;
  padding: 50px;
  border-radius: 25px;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  max-width: 440px;
  margin-left: auto;
  * {
    color: ${colors.navy};
  }
  .contact-heading {
    font-family: ${fontFamilies.beausite};
    font-size: 26px;
    font-weight: 700;
    margin: 0 0 20px 0;
  }
  .detailAddress {
    a {
      text-decoration: underline;
    }
  }
  .col-1,
  .col-2 {
    margin-bottom: 20px;
  }
  .detail {
    padding-left: 20px;
    position: relative;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    svg {
      position: absolute;
      left: 0;
      top: 3px;
    }
    .heading {
      font-size: 16px;
      font-weight: 700;
      width: fit-content;
    }
    p {
      margin: 0;
    }
  }
  .detail-content {
    display: flex;
    column-gap: 5px;
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    li {
      a {
        display: block;
        ${defaultButtonStyle};
        background-color: ${colors.navy};
        color: ${colors.white};
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  ${mediaQuery.tabletLandScapeDown} {
    margin: 0 auto;
  }
  ${mediaQuery.mobileDown} {
    .buttons {
      flex-direction: column;
      li:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
  @media only screen and (max-height: 840px) {
    padding: 30px;
    max-width: 540px;
    h3 {
      margin-bottom: 15px;
      font-size: 20px;
    }
    .details-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 15px;
    }
    .buttons {
      justify-content: unset;
      li:not(:last-child) {
        margin-right: 30px;
      }
    }
  }

  @media only screen and (max-height: 740px) {
    padding: 20px;

    .detail {
      display: flex;
      column-gap: 8px;
    }

    .contact-person-details {
      align-items: center;
      h4 {
        margin-right: 5px;
      }
    }
    .details-wrapper {
      display: flex;
      flex-direction: column;
      gap: 0px;
    }
    .address-wrapper {
      display: flex;
      column-gap: 8px;
      flex-wrap: wrap;
    }
  }

  ${mediaQuery.tabletLandScapeDown} {
    width: 90%;
    max-width: none;
    display: flex;
    flex-direction: column;
    padding-top: 30px;

    h3 {
      display: none;
    }

    .details-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      .col-1 {
        display: flex;
        justify-content: space-between;
        width: 50%;
      }

      .col-2 {
        display: flex;
        width: 50%;
        justify-content: space-around;
        .contact-person-details {
          flex-direction: column;
          align-items: flex-start;
        }
      }

      .detailAddress {
        width: 50%;
      }

      .smScreenUp {
        display: block;
      }
    }
  }

  ${mediaQuery.tabletDown} {
    .details-wrapper {
      flex-direction: column;

      .detailAddress {
        width: 100%;
      }

      .col-1 {
        display: block;
        width: 100%;
      }

      .col-2 {
        display: block;
        width: 100%;
      }
    }

    .buttons {
      flex-direction: row;
    }
  }

  // Blocks content override
  .rte {
    p {
      margin: 0 !important;
    }
  }
`;
