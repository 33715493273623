import { Blocks, ErrorMsg, Header, Hero, HeroSlider, SEO } from "@shared";
import { PageProps, graphql } from "gatsby";
import { usePageMeta, useSetVillageRelatedData, useVillageSubpageData } from "@util/logicHooks";

import { DevelopmentProgress } from "@components/village";
import { Query } from "@graphql-types";
import React from "react";
import { VillagePageContext } from "@state/types";
import { VillageRelatedData } from "@util/types";
import { VillageExtraHeroContent } from "@components/village";

interface Data extends VillageRelatedData {}

type PageData = Data & Query;
interface Props extends PageProps {
  data: PageData;
  pageContext: VillagePageContext;
}

export default function VillageTemplate({
  data: {
    sanityVillage,
    sanityHeader,
    villageEvents,
    regionEvents,
    otherNews,
    generalNews,
    villageNews,
    villagesNews,
  },
  pageContext,
  ...rest
}: Props) {
  if (sanityVillage == null)
    return <ErrorMsg data={sanityVillage} msg="Error fetching data for page" />;

  const { pageType, seo } = pageContext;
  const isOverviewPage = pageType === "overview";
  const {
    _type,
    slug,
    villageColour,
    developmentProgress,
    developmentTitle,
    secondaryVillageColour,
    overviewHero,
    showBanner,
    showContactDetailsInHero,
    overviewHeroSlider,
  } = sanityVillage;

  const events = { villageEvents, regionEvents };
  const news = { villageNews, regionNews: otherNews, generalNews, villagesNews };

  // hooks
  usePageMeta(villageColour, _type, pageContext);
  useSetVillageRelatedData({ events, news });
  const subpageData = useVillageSubpageData(sanityVillage, pageType);

  const extraContent = showContactDetailsInHero ? (
    <VillageExtraHeroContent data={sanityVillage} />
  ) : undefined;

  const heroAttr = {
    data: subpageData?.hero,
    extraContent,
  };
  return (
    <div>
      <SEO
        {...rest}
        seoData={seo!}
        slug={slug?.current as string}
        overwriteImage={
          overviewHero?.backgroundImages && overviewHero?.backgroundImages[0]?.asset?.url
        }
      />
      <Header data={sanityHeader} village={sanityVillage} />

      {isOverviewPage && Boolean(overviewHeroSlider?.slides?.length) ? (
        <HeroSlider data={overviewHeroSlider} extraContent={extraContent} />
      ) : (
        <Hero {...heroAttr} />
      )}
      {showBanner && isOverviewPage && developmentProgress && (
        <DevelopmentProgress
          title={developmentTitle}
          data={developmentProgress}
          color={secondaryVillageColour}
        />
      )}
      <Blocks data={subpageData?.blocks} />
    </div>
  );
}

export const query = graphql`
  query villageOverviewQuery($slug: String, $region: String, $parentRegion: String) {
    sanityHeader {
      ...sanityHeader
    }
    sanityVillage(slug: { current: { eq: $slug } }) {
      ...sanityVillage
      ...sanityVillageContact
    }
    villageEvents: allSanityEvent(
      filter: { eventVillages: { elemMatch: { slug: { current: { eq: $slug } } } } }
      sort: { fields: dateTime, order: ASC }
    ) {
      nodes {
        ...sanityEvent
      }
    }
    regionEvents: allSanityEvent(
      filter: {
        eventVillages: {
          elemMatch: { region: { slug: { current: { in: [$parentRegion, $region] } } } }
        }
      }
      sort: { fields: dateTime, order: ASC }
    ) {
      nodes {
        ...sanityEvent
      }
    }
    villageNews: allSanityNews(
      filter: { villageRef: { slug: { current: { eq: $slug } } } }
      limit: 2
      sort: { order: DESC, fields: _createdAt }
    ) {
      nodes {
        ...sanityNewsPreview
      }
    }
    otherNews: allSanityNews(
      filter: { regionRef: { slug: { current: { eq: $region } } } }
      limit: 2
      sort: { order: DESC, fields: _createdAt }
    ) {
      nodes {
        ...sanityNewsPreview
      }
    }
    generalNews: allSanityNews(
      filter: { categories: { elemMatch: { title: { eq: "General" } } } }
      limit: 2
      sort: { order: DESC, fields: _createdAt }
    ) {
      nodes {
        ...sanityNewsPreview
      }
    }
    villagesNews: allSanityNews(
      filter: { villages: { elemMatch: { slug: { current: { eq: $slug } } } } }
      limit: 2
      sort: { order: DESC, fields: _createdAt }
    ) {
      nodes {
        ...sanityNewsPreview
      }
    }
  }
`;
